<template>
  <CreateOrderForm
    store="order_payment"
    withDeclaration
    withAttachment
    withPayFreightCharge
  />
</template>

<script>
import CreateOrderForm from "../../components/CreateOrderForm.vue";
export default {
  components: { CreateOrderForm },
};
</script>
